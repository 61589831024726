<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Dealer</label>
            <b-select
              v-model="filter.dealer"
              :options="filterDealer"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            ref="table"
            hover
            responsive
            class="mt-2"
            :per-page="tableSettings.perPage"
            :current-page="tableSettings.currentPage"
            :items="myProvider"
            :fields="tableSettings.fields"
            :sort-by.sync="tableSettings.sortBy"
            :sort-desc.sync="tableSettings.sortDesc"
            :sort-direction="tableSettings.sortDirection"
            :filter="tableSettings.filter"
            :filter-included-fields="tableSettings.filterOn"
            show-empty
          >
            <template #cell(index)="data">
              {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index +
                1)
              }}
            </template>

            <template #cell(active)="data">
              {{ data.item.active === 1 ? "Yes" : "No" }}
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  @click="onShowProductDetails(row.item)"
                >
                  Show Products
                </b-button>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableSettings.perPage"
                  :options="tableSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableSettings.currentPage"
                :total-rows="tableSettings.totalRows"
                :per-page="tableSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-product-show"
      scrollable
      size="xl"
      no-close-on-backdrop
      title="Products List"
      class="mb-5"
      @ok="onConfirmSubmit"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <div>
            <b-row>
              <b-col
                cols="12"
                sm="12"
              >

                <ValidationProvider
                  #default="{ errors }"
                  name="delivery_receipt"
                  vid="delivery_receipt"
                  rules="required"
                >
                  <div class="form-group">
                    <label for="delivery_receipt">Delivery Receipt</label>
                    <b-input
                      id="delivery_receipt"
                      v-model="stock_details.delivery_receipt"
                      type="text"
                      :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                      placeholder="Enter Delivery Receipt"
                      autocomplete="off"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </div>
                </ValidationProvider>

              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                cols="12"
                sm="4"
              >
                <div class="form-group">
                  <label>Product Category</label>
                  <v-select
                    v-model="product.product_category_id"
                    :options="product_categories"
                  />
                </div>
              </b-col>

              <b-col
                cols="12"
                sm="4"
              >
                <div class="form-group">
                  <label>Product</label>
                  <v-select
                    v-model="product.product_id"
                    :options="products"
                  />
                </div>
              </b-col>

              <b-col
                cols="12"
                sm="4"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="quantity"
                  vid="quantity"
                  rules="numeric"
                >

                  <div class="form-group">
                    <label for="quantity">Quantity</label>
                    <b-input
                      id="quantity"
                      v-model="product.quantity"
                      type="number"
                      :class="`${errors.length > 0 ? 'is-invalid' : ''} form-control-sm `"
                      placeholder="enter quantity"
                      autocomplete="off"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </div>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col cols="12">
                <div />
                <b-button
                  type="button"
                  variant="success"
                  @click="addItem"
                >
                  Add Product
                </b-button>
              </b-col>
            </b-row>

            <b-table
              striped
              hover
              :items="productlist_dealer"
              :fields="tableShowProductDetails.fields"
            >
              <template #cell(price)="data">

                <ValidationProvider
                  #default="{ errors }"
                  name="price"
                  vid="price"
                  rules="numeric"
                >
                  <div class="form-group">
                    <b-input
                      id="price"
                      v-model="data.item.amount"
                      class="price form-control-sm"
                      type="number"
                      placeholder="Enter Amount "
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </div>
                </ValidationProvider>

              </template>
              <template #cell(add_quantity)="data">

                <ValidationProvider
                  #default="{ errors }"
                  name="add_quantity"
                  vid="add_quantity"
                  rules="numeric|required"
                >
                  <div class="form-group">
                    <b-input
                      id="add_quantity"
                      v-model="data.item.add_quantity"
                      class="add_quantity form-control-sm"
                      type="number"
                      placeholder="Enter Add Quantity "
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </div>
                </ValidationProvider>

              </template>
            </b-table>
          </div>
          <b-row>
            <b-col cols="12">
              <div class="form-group">
                <label for="comment">Enter Comment: </label>
                <textarea
                  id="comment"
                  v-model="stock_details.comment"
                  name="comment"
                  rows="5"
                  placeholder="Enter Comment"
                  class="form-control"
                />
              </div>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="disableSubmit"
          @click="ok()"
        >
          Submit
        </b-button>
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedUserService, ListService, StockService } from '@/services'
import formatter from '@/mixins/formatter'
// import Input from '@/components/FormInput/InputType'
// import InputSelect from '@/components/FormInput/InputSelect'

export default {
  name: 'UserBooking',

  middleware: ['auth', 'user'],

  mixins: [formatter],

  data () {
    return {
      isBusy: true,
      editMode: false,
      stock_details: {
        user_id: '',
        dealer_id: '',
        inventory_type: '',
        comment: '',
        delivery_receipt: ''

      },
      dealers: [],
      filter: {
        dealer: 'All'
      },
      product: {
        product_category_id: '',
        product_id: '',
        quantity: ''
      },
      product_categories: [],
      products: [],
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'area_code', sortable: true },
          { key: 'area_name', sortable: true },
          { key: 'dealer_code', sortable: true },
          { key: 'dealer_name', sortable: true },
          { key: 'address', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' }
        ]
      },
      tableProductDetails: {
        fields: [
          {
            key: 'product_category_id',
            sortable: true
          },
          {
            key: 'product_category_name',
            sortable: true
          },
          {
            key: 'product_id',
            sortable: true
          },
          {
            key: 'product_name',
            sortable: true
          },
          {
            key: 'quantity',
            sortable: true
          },
          'action'
        ]
      },
      tableShowProductDetails: {
        fields: [
          {
            key: 'product_category_name',
            sortable: true
          },
          {
            key: 'product_name',
            sortable: true
          },
          {
            key: 'quantity',
            sortable: true,
            label: 'Total booked quantity'
          },
          {
            key: 'price',
            sortable: true,
            label: 'Price (per quantity)'
          },
          {
            key: 'add_quantity',
            sortable: true
          }
        ]

      },
      items: [],
      productlist_dealer: [],
      user: this.$store.getters['auth/user']
    }
  },

  computed: {
    filterDealer () {
      return [{ value: 'All', text: 'All' }].concat(this.dealers)
    },

    disableSubmit () {
      return this.productlist_dealer.filter(el => el.add_quantity > 0).length > 0 ? false : 'disabled'
    }
  },
  watch: {
    'product.product_category_id.value' (value) {
      this.getProduct(value)
    },
    'filter.dealer' () {
      this.$refs.table.refresh()
    }

  },

  metaInfo () {

  },

  mounted () {
    core.index()
    this.getProductCategory()
    this.getDealer()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SharedUserService.getDealerByDcp(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_dealer=${this.filter.dealer}&user_id=${this.user.id}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getDealer () {
      await ListService.getDealers().then(({ data }) => {
        this.dealers = data.map(({ id, dealer_name }) => {
          return { value: id, text: dealer_name }
        })
      })
    },

    async getProductCategory () {
      this.product_categories = []
      await ListService.getProductCategories().then(({ data }) => {
        data.forEach(item => {
          this.product_categories.push({
            value: item.id,
            text: item.product_category_name,
            label: item.product_category_name
          })
        })
      })
    },

    async getProduct (productCategories) {
      this.products = []
      await ListService.getProducts(`product_category_id=${productCategories}`).then(({ data }) => {
        data.forEach(item => {
          this.products.push({
            value: item.id,
            text: item.product_name,
            label: item.product_name
          })
        })
      })
    },

    async onShowProductDetails (item) {
      this.stock_details.comment = ''
      this.stock_details.delivery_receipt = ''

      this.stock_details.user_id = item.user_id
      this.stock_details.dealer_id = item.dealer_id
      this.stock_details.inventory_type = 'add'

      this.$bvModal.show('modal-product-show')

      this.productlist_dealer = []
      await StockService.getProductsListByDealer(`dealer_id=${item.dealer_id}&status=booking`).then(({ data }) => {
        this.productlist_dealer = data.data.map(el => {
          return { ...el, add_quantity: 0, amount: 0 }
        })
      })
    },

    async onSubmitPostForm () {
      const obj = {
        dealer_id: this.stock_details.dealer_id,
        inventory_type: this.stock_details.inventory_type,
        status: 'booking',
        product_details: this.productlist_dealer.filter(el => el.add_quantity > 0),
        comment: this.stock_details.comment,
        delivery_receipt: this.stock_details.delivery_receipt
      }

      this.isBusy = true
      if (obj.product_details.length > 0) {
        await StockService.post(obj).then(response => {
          this.isBusy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$bvModal.hide('modal-product-show')
          })
        }).catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
            }
          }
          this.isBusy = false
        })
      }
    },

    async onConfirmSubmit (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          if (!this.editMode) {
            this.$swal.fire({
              title: 'Are you sure you you want to add this new sales loading ?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#FF2929',
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.onSubmitPostForm()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          }
        } else {
          this.$swal.fire({
            icon: 'warning',
            title: 'Validation Error',
            text: 'Oppss! There were problem with your inputs.',
            confirmButtonColor: '#FF2929',
            confirmButtonText: 'Dismiss'
          })
        }
      })
    },
    async addItem () {
      const productCategoryId = this.product.product_category_id
      const productId = this.product.product_id
      const quantity = this.product.quantity
      if (productCategoryId === '' || productId === '' || quantity === '') {
        this.$swal.fire({
          icon: 'warning',
          title: 'Validation Error',
          text: 'Oppss! There were problem with your inputs.',
          confirmButtonColor: '#FF2929',
          confirmButtonText: 'Dismiss'
        })
        return false
      }

      const isExisting = this.productlist_dealer?.filter(
        el => el.product_id === this.product.product_id.value
      )

      if (isExisting.length) {
        const newData = this.productlist_dealer.map(el => {
          if (
            el.product_id === this.product.product_id.value
          ) {
            el.add_quantity = Number(el.quantity) + Number(this.product.quantity)
            return el
          }

          return el
        })

        this.productlist_dealer = newData
        this.product.product_category_id = ''
        this.product.product_id = ''
        this.product.quantity = ''
      } else {
        this.productlist_dealer.push({
          product_category_name: this.product.product_category_id.label,
          product_category_id: this.product.product_category_id.value,
          product_name: this.product.product_id.label,
          product_id: this.product.product_id.value,
          add_quantity: this.product.quantity,
          dealer_id: this.stock_details.dealer_id,
          amount: 0,
          id: 0
        })
        this.product.product_category_id = ''
        this.product.product_id = ''
        this.product.quantity = ''
      }
    }

  }
}
</script>
